import { call, callLinkReplace, getAppInfo, popWebview, callWebview, showToast } from '@/bridge';
import { hideLoading } from '@/components/BasicLoading';
import { showH5Popup } from '@/components/PopupMessage';
import { setCookie } from '@/module/cookie';
import { NetworkTipTextMap } from './constant';
import request from './request';
import { APP_ENV, isEmpty, isPC, isShopeeApp, isShopeePayApp } from './util';
import { getSessionItem, removeItem, setSessionItem } from '@/module/storage';
import { getUrlParamByKey, replaceParam } from '@/module/url';
import { AppEnvType } from '@/types/common';
import { getNow } from '@/module/date';
import { safeJSONParse } from '@/module/util';
import { formatMsg } from '@/locales';

const { REACT_APP_ENV } = process.env;

export function isShopeeDomain() {
  return /\.shopee\./.test(location.host);
}

const genLoginUrl = (redirectUrl: string) => {
  let env = REACT_APP_ENV;
  env = ['uat', 'live'].indexOf(env) >= 0 ? env : 'test';

  return `https://${env !== 'live' ? `${env}.` : ''}${
    process.env.BRIDGE_HOST
  }/authenticate/oauth?client_id=5&version=2${
    !isPC() ? '&__mobile__=1' : ''
  }&redirect_uri=${redirectUrl}`;
};
/**
 * @deprecated
 * 登录统一用 goLogin 处理
 */
export const goWsaUrl = (login_value?: Record<string, any>) => {
  // const legalURLParams = [
  //   'product_id',
  //   'ins_level',
  //   'entry',
  //   'order_id',
  //   'policy_id',
  //   'tab',
  //   'from',
  //   'data',
  //   'is_birth',
  //   'kyc_type',
  //   'relation',
  //   'member_id',
  // ]; // 合法参数，不能随便携带参数
  // // 如果链接携带参数，需要对携带的参数做过滤
  // if (url.indexOf('?') > -1) {
  //   const queries = parseQuery() as any;
  //   const legalObject: any = {};
  //   Object.keys(queries).forEach((query) => {
  //     if (legalURLParams.includes(query)) {
  //       legalObject[query] = queries[query];
  //     }
  //   });
  //   const legalSearch = `?${parseObjectToUrl(legalObject)}`;
  //   url = `${url.substring(0, url.indexOf('?'))}${legalSearch}`;
  // }
  // 回跳的url形如 'https://cashloan.test.shopeekredit.co.id/activate?code=xxx&&errcode=0&errmsg=OK';

  // console.log(loginUrl);
  const url = encodeURIComponent(
    Object.entries(login_value || {}).reduce((result, [key, val]) => {
      return replaceParam(key, val, result);
    }, window.location.href),
  );
  // if (isShopeeApp && isAndroid()) {
  //   call('login', {
  //     redirectPath: `home?apprl=${url}`,
  //     hidePopup: 1,
  //     redirectTab: 1,
  //   }).then((res) => {
  //     if (res && (res.error == 1 || res.error == BridgeCode.timeout)) {
  //       console.error('login_faild', res);
  //       callLinkReplace(genLoginUrl(url));
  //     }
  //   });
  //   return;
  // }
  callLinkReplace(genLoginUrl(url));
};
/**
 * nonce 方案，获取nonce
 * @returns
 */
const requestLoginNonce = async () => {
  if (!isShopeeApp) {
    return '';
  }
  const appInfo = (await getAppInfo()) || {};
  if (!appInfo.appVersion || +appInfo.appVersion < 33700) {
    console.warn('login_nonce', { code: -1, version: appInfo.appVersion });
    return '';
  }
  const res =
    (await call('requestLoginNonce', {
      clientID: 'shopee-insurance',
    })) || {};
  let nonce = '';
  if (res.error == 0 && res.data && res.data.nonce) {
    nonce = res.data.nonce;
  }
  console.warn('login_nonce', { code: res.error, nonce });
  return nonce;
};
let loginLock = false;
// PH / TH 等seainsure broker域名的登陆
/**
 * @deprecated
 * 登录统一用 goLogin处理
 */
export const seaInsureBrokerLogin = async (login_value?: Record<string, any>) => {
  if (loginLock) return;
  loginLock = true;
  // 兼容链接携带 nonce 的方案
  let nonce = getUrlParamByKey('nonce');
  if (!nonce) {
    nonce = await requestLoginNonce();
    if (!nonce) {
      console.warn('login_code', { code: 1 });
      return goWsaUrl(login_value);
    }
  }

  request({
    url: '/api/h5/user_login',
    method: 'POST',
    data: {
      nonce,
    },
  })
    .then((res: { code: number }) => {
      loginLock = false;
      const code = (res || {}).code;
      console.warn('login_code', { code, nonce });
      if (code == 0) {
        callLinkReplace(replaceParam('exit_state', '', replaceParam('nonce', '')));
      } else {
        hideLoading();
        showH5Popup({
          title: NetworkTipTextMap.title[process.env.REGION] || 'Network Error',
          content:
            NetworkTipTextMap.content[process.env.REGION] ||
            'Network Error. Please Try Again Later',
          okText: NetworkTipTextMap.retry[process.env.REGION] || 'Retry',
          onOk: () => {
            location.reload();
          },
        });
      }
    })
    .catch((error) => {
      loginLock = false;
      console.warn('login_code', { code: -1, nonce, error });
    });
};
export function detectStokenEnv() {
  if (['th', 'my'].includes(process.env.REGION) && APP_ENV == AppEnvType.ShopeePay) return true;
  return false;
}
async function getUserId() {
  const res = await call('getAccountInfo');
  if (res && res.data && res.data.userid) {
    setCookie('userid', res.data.userid, { expires: 47 });
  }
}
function getStoken(): Promise<string> {
  return new Promise((resolve) => {
    const getToken = async (count: number) => {
      if (count >= 2) {
        return resolve('');
      }
      const res = await call('requestAuthToken', {
        productId: '546e9a0b-ef47-497c-b74b-7b2283e5c109',
      });
      if (res && res.data && res.data.token) {
        return resolve(res.data.token as string);
      }
      console.warn('requestAuthToken', res);
      getToken(++count);
    };
    getToken(0);
  });
}
function _back2PrePage(type?: number) {
  // 预期：如果未登录APP会跳转去登录，如果已登录，这里总是会拿到 token，如果跑到这里来，就先pop掉所有的页面
  const count = 1; // +(getUrlParamByKey2('ins_level') || 1);
  if (type == 1) {
    // MY shopeepay
    console.error('goLoginSPP', count);
  } else if (isShopeeApp) {
    console.error('goLogin', count);
  }
  popWebview({ count });
}
async function _goLoginSPP() {
  if (!detectStokenEnv()) return false;
  getUserId();
  const token = await getStoken();
  if (token) {
    setCookie('s_token', token, { expires: 47 });
    location.reload();
    return true;
  }
  _back2PrePage(1);
  return true;
}
const sessionKey = 'gologin_times';
let debounce = 0;
export async function goLogin(login_value?: Record<string, any>) {
  if (getNow() - debounce < 300) return;
  debounce = getNow();
  const val = await getSessionItem(sessionKey);
  if (val && val >= 3) {
    console.warn(sessionKey, val);
    removeItem(sessionKey, 'session');
    _back2PrePage();
    return;
  }
  setSessionItem(sessionKey, val ? val + 1 : 1, { expire: 3000 });
  if (await _goLoginSPP()) return;
  if (isShopeeDomain()) {
    return goWsaUrl(login_value);
  }
  return seaInsureBrokerLogin(login_value);
}
export const isTouristByCookie = () => {
  return false;
};

const NEED_LINK_TO_SHOPEE = [90035, 90036];
export const linkToSpStorageKey = 'linkToSp';
let isInLinkToSpFlow = false;
let linkToSpCache: string;
getSessionItem(linkToSpStorageKey).then((res) => {
  linkToSpCache = res;
});

export const detectLinkToSp = (code: string | number) => {
  
  // eslint-disable-next-line no-unreachable
  return false;
};
