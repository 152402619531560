import { getLocalItem, setLocalItem } from '@/module/storage';

const IS_SUPPORT_WEBP = 'IS_SUPPORT_WEBP';
const IS_SUPPORT_AVIF = 'IS_SUPPORT_AVIF';

let isSupportWebpCache: boolean;
let isSupportAvifCache: boolean;

export const imgRegExp = /\.(a?png|jpe?g|webp|gif|ico|avif)$/;
export async function isSupportWebp() {
  try {
    if (isSupportWebpCache !== undefined) {
      return isSupportWebpCache;
    }
    const item = await getLocalItem(IS_SUPPORT_WEBP);
    if (item) {
      isSupportWebpCache = +item == 1;
      return isSupportWebpCache;
    }
    isSupportWebpCache =
      document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;

    setLocalItem(IS_SUPPORT_WEBP, isSupportWebpCache ? 1 : 0, { expire: '9999d' });
    return isSupportWebpCache;
  } catch (err) {
    return false;
  }
}

export async function isSupportAvif(): Promise<boolean> {
  return new Promise(async (resolve) => {
    if (isSupportAvifCache !== undefined) {
      return isSupportAvifCache;
    }
    const item = await getLocalItem(IS_SUPPORT_AVIF);
    if (item) {
      isSupportAvifCache = +item == 1;
      return isSupportAvifCache;
    }
    const image = new Image();
    image.src =
      'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=';
    image.onload = () => {
      if (image.width > 0 && image.height > 0) {
        isSupportAvifCache = true;
        setLocalItem(IS_SUPPORT_AVIF, 1, { expire: '9999d' });
        resolve(isSupportAvifCache);
      } else {
        isSupportAvifCache = false;
        setLocalItem(IS_SUPPORT_AVIF, 0, { expire: '9999d' });
        resolve(isSupportAvifCache);
      }
    };
    image.onerror = () => {
      isSupportAvifCache = false;
      setLocalItem(IS_SUPPORT_AVIF, 0, { expire: '9999d' });
      resolve(isSupportAvifCache);
    };
  });
}

type ImgCfg = {
  compressRate?: string | number;
  suffix?: 'original' | string;
};
const imgCfg = PFC.getPFC<Record<string, any>>('imgCfg') as ImgCfg;
// const imgCfg: ImgCfg = {
//   compressRate: '@quality_q100',
//   suffix: 'png',
// };
const MMS_DOMAIN = /\.img\.susercontent\.com/;

const getCompressImgUrl = (url: string) => {
  if (!url) return url;
  const reg = /@quality_q\d+/;
  // 链接上没有压缩参数，直接返回
  if (!reg.test(url)) return url;
  if (!imgCfg || !imgCfg.compressRate) return url;
  if (typeof +imgCfg.compressRate === 'number') {
    return url.replace(reg, `@quality_q${imgCfg.compressRate}`);
  }
  if (typeof imgCfg.compressRate === 'string' && imgCfg.compressRate.indexOf('@quality_q') > -1) {
    return url.replace(reg, imgCfg.compressRate);
  }
  return url;
};
export function transformImgUrl(url: string) {
  if (!url) return '';
  if (MMS_DOMAIN.test(url)) {
    const compressUrl = getCompressImgUrl(url);
    if (imgCfg && imgCfg.suffix === 'original') {
      // 原始格式
      return compressUrl;
    } else if (imgCfg && imgCfg.suffix) {
      // 替换后缀，防止多次替换，导致url缓存失效
      return compressUrl.replace(imgRegExp, `.${imgCfg.suffix}`);
    } else if (isSupportAvifCache) {
      return compressUrl.replace(imgRegExp, `.avif`);
    } else if (isSupportWebpCache) {
      return compressUrl.replace(imgRegExp, `.webp`);
    }
    return compressUrl;
  }
  return url;
}
