import { supportIntersection } from '@/common/util';
import useMemoizedFn from '@/hooks/useMemoizeFn';
import classNames from 'classnames';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Background from '@/assets/images/background.svg';
import './index.scss';
import { isSupportAvif, isSupportWebp, transformImgUrl } from './help';

let intersetionObserver: null | IntersectionObserver = null;

isSupportWebp();
isSupportAvif();

function loadImg(cur: string, cb: (error: any, url: string) => void, count = 0) {
  const img = new Image();
  img.onload = function () {
    cb(null, cur);
  };
  img.onerror = function (err) {
    if (count > 0) {
      return cb(err, cur);
    }
    const join = cur.indexOf('?') >= 0 ? '&' : '?';
    loadImg(`${cur + join}t=${Date.now()}`, cb, count + 1);
  };
  img.src = cur;
}
const imgMap: Record<string, Function[]> = {};
function getIntersetionObserver(src: string, cb: Function) {
  if (!imgMap[src]) imgMap[src] = [];
  imgMap[src].push(cb);
  if (intersetionObserver) return intersetionObserver;
  const intersetion = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target as HTMLImageElement;
          if (lazyImage.dataset.loaded) {
            intersetion.unobserve(lazyImage);
            return;
          }
          const sourceSrc = lazyImage.dataset.src;
          if (!sourceSrc || !imgMap[sourceSrc]) return;
          loadImg(sourceSrc, (err: any, url: string) => {
            if (!err) {
              lazyImage.dataset.loaded = '1';
              intersetion.unobserve(lazyImage);
            }
            imgMap[sourceSrc].forEach((item) => {
              item(err, url);
            });
          });

          // 将 URL 设置为图片的 src 属性
          // lazyImage.src = lazyImage.dataset.src!;
          // lazyImage.dataset.loaded = '1';
          // intersetion.unobserve(lazyImage);
        }
      });
    },
    { rootMargin: '100px' },
  );
  intersetionObserver = intersetion;
  return intersetion;
}

type LazyImageProps = Pick<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    src?: string;
    immediate?: boolean;
    // 是否有滚动条
    skeleton?: boolean;
  },
  'key' | keyof React.ImgHTMLAttributes<HTMLImageElement> | 'immediate' | 'skeleton'
> &
  React.RefAttributes<unknown>;

export type ILazyImgRef = {
  imgRef: React.RefObject<HTMLImageElement> | null;
};

function compareEqual(preProps: LazyImageProps, nextProps: LazyImageProps) {
  if (
    nextProps.src != preProps.src ||
    nextProps.immediate != preProps.immediate ||
    nextProps.className != preProps.className
  )
    return false;
  return true;
}
/**
 * 懒加载组件，如果需要直接渲染图片的，传 immediate = true
 * @param props
 * @returns
 */
const LazyImage = forwardRef<ILazyImgRef, LazyImageProps>((props: LazyImageProps, comRef) => {
  const { src: originSrc, immediate, skeleton, className, onError, ...rawProps } = props;
  const src = transformImgUrl(originSrc!);
  const [curSrc, setCurSrc] = useState(props.immediate ? src : '');
  const imgRef = useRef<HTMLImageElement>(null);
  // const onError = useMemoizedFn((error) => {
  //   const s = error.target.src;
  //   if (!s) return;
  //   if (s.match(/[?&]t=\d+/)) {
  //     if (props.onError) {
  //       props.onError(error);
  //     }
  //     console.error('img_load_error', s);
  //     setCurSrc('');
  //     return;
  //   }
  //   const join = s.indexOf('?') >= 0 ? '&' : '?';
  //   setCurSrc(`${s + join}t=${Date.now()}`);
  // });

  useImperativeHandle(comRef, () => {
    return {
      imgRef,
    };
  });

  const onLoad = useMemoizedFn((e) => {
    if (!curSrc) return;
    const target = e.target;
    target.classList.remove('lazy-img_placeholder');
    target.classList.remove('skeleton');

    props.onLoad?.(e);
  });

  useEffect(() => {
    if (immediate) {
      
      return;
    }
    if (!src) return;
    if (!supportIntersection()) {
      setCurSrc(src);
      return;
    }
    if (!imgRef.current) return;
    const observer = getIntersetionObserver(src, (err: any, url: string) => {
      if (err) {
        if (onError) {
          onError(err);
        }
        // 统一交给idap的资源上报
        // console.error('img_load_error', err);
        return;
      }
      setCurSrc(url);
    });
    observer.observe(imgRef.current);
  }, [immediate, onError, src]);
  return (
    <img
      {...rawProps}
      ref={imgRef}
      src={curSrc || Background}
      data-src={src}
      className={classNames(className, {
        skeleton,
        'lazy-img_placeholder': true,
      })}
      // onError={onError}
      onLoad={onLoad}
    />
  );
});
export default memo(LazyImage, compareEqual);
