import { docRequest } from './docRequest';

export enum EBusinessType {
  E_Invoice = 1,
}

export type ShareFileReq = {
  file_name: string;
  s_url: string;
  business_type: EBusinessType;
};

export type ShareFileRes = {
  file_url: string;
};

export const shareFile = (params: ShareFileReq) => {
  return docRequest<ShareFileRes>({
    url: '/api/h5/share_file',
    data: params,
  });
};
