export function loadImg(src: string) {
  const img = new Image();

  return new Promise<void>((resolve, rejcet) => {
    img.onload = () => {
      resolve();
    };
    img.onerror = () => {
      rejcet();
    };

    img.src = src;
  });
}

export function findTag(tagName: string, element: any, root: any) {
  while (element && element.tagName.toLowerCase() !== tagName && element != root) {
    element = element.parentElement;
  }
  return element;
}
