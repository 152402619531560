import { call } from '@/bridge';
import { MutableRefObject, useRef } from 'react';

const fnArray: MutableRefObject<Function>[] = [];

// 中断tapback
export class InterruptBackError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InterruptBackError';
  }
}
// 继续执行后续行为
export class ContinueBackError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ContinueBackError';
  }
}

function executePromisesSerially(func: MutableRefObject<Function>[]) {
  return func.reduce((pre, cur) => {
    return pre.then(async () => {
      try {
        const res = await cur.current();
        return res;
      } catch (error) {
        if (error instanceof ContinueBackError) {
          // 正常异常 继续执行后置回调
          console.log('ContinueBackError');

          return;
        }
        throw error;
      }
    });
  }, Promise.resolve());
}

let isUseDidTapBackMounted = false;

export function useDidTapBack(fn: (...args: any[]) => any, options?: { pageName?: string }) {
  const tapRef = useRef<Function>(fn);
  // 每次都用最新函数
  tapRef.current = fn;
  if (fnArray.indexOf(tapRef) == -1) {
    fnArray.push(tapRef);
  }
  // 只允许执行一次注册事件
  // 防止同一页面多个组件调用 导致多次注册
  if (!isUseDidTapBackMounted) {
    isUseDidTapBackMounted = true;
    call('didTapBack', async function () {
      try {
        // 串行调用
        await executePromisesSerially(fnArray);
      } catch (error) {
        if (error instanceof InterruptBackError) {
          // 正常异常 中断
          console.log('InterruptBackError');
          return;
        }
        console.warn('useDidTapBack', { error });
      }
      console.warn('didTapBack');
      if (options) {
        call('popWebView', { data: JSON.stringify({ ...options }) });
      } else {
        call('popWebView');
      }
    });
  }
}
